import React, { useContext } from "react";
import { useIsMobile } from "../../utils/UtilityService";
import { AVContext } from "../../utils/AVContext";
import { ImPhone } from "react-icons/im";
import { BsEnvelopeFill } from "react-icons/bs";
import { TbWorld } from "react-icons/tb";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper";
import { gold } from "../../utils/AVConstants";
import data from "../../utils/data.json";
import en from "../../utils/en.json";
import es from "../../utils/es.json";
import "swiper/css";
import "swiper/css/navigation";
import "./MemberCV.scss";

const MemberCV = () => {
	const isMobile = useIsMobile();

	const {
		state: { language, activeMember },
	} = useContext(AVContext);

	const translations = language === "en" ? en : es;

	const members = language === "en" ? data?.membersEN : data?.membersES;

	const mobileProps = {
		initialSlide: activeMember,
		pagination: {
			clickable: true,
		},
		modules: [Pagination],
	};

	const desktopProps = {
		initialSlide: activeMember,
		navigation: true,
		modules: [Navigation],
	};

	return (
		<section id='memberCV' className='avlegalfirm-section'>
			<Swiper {...(isMobile ? mobileProps : desktopProps)}>
				{members?.map((member, i) => (
					<SwiperSlide key={`slide-${i}`}>
						<div className='avlegalfirm-popupBox--cv'>
							<div className='avlegalfirm-popupBox--cv_contactData'>
								<img
									src={require(`../../utils/images/${member?.nobgPicture}`)}
									alt={`AV Law Firm - Member: ${member?.fullName}`}
									loading='lazy'
								/>
								<h1 className='mt-25'>{member?.fullName}</h1>
								<div>
									{member?.profession && (
										<p className='mb-15'>
											{member?.profession}
										</p>
									)}
									{member?.lawyerId && (
										<p>{`Lawyer ID: ${member.lawyerId}`}</p>
									)}
									<h2>{translations.contact}</h2>
									{member?.mobilePhone && (
										<div className='avlegalfirm-popupBox--cv_contactData-row avlegalfirm-popupBox--cv_contactData-row--phone'>
											<ImPhone color={gold} />
											<p>{`+506 ${member?.mobilePhone}`}</p>
										</div>
									)}
									<div className='avlegalfirm-popupBox--cv_contactData-row'>
										<BsEnvelopeFill color={gold} />
										<p>{member?.email}</p>
									</div>
									{member?.practiceAreas && (
										<div>
											<h2>
												{translations.practiceAreas}
											</h2>
											{member.practiceAreas.map(
												(area, i) => (
													<p key={`area-${i}`}>
														{area}
													</p>
												)
											)}
										</div>
									)}
									<div>
										<h2>{translations.languages}</h2>
										<div className='avlegalfirm-popupBox--cv_contactData-row'>
											<TbWorld color={gold} />
											<p>
												{member?.languages?.join(", ")}
											</p>
										</div>
									</div>
								</div>
							</div>
							<div className='avlegalfirm-popupBox--cv_professionalData'>
								{member?.education && (
									<div className='avlegalfirm-popupBox--cv_professionalData-list--wrapper'>
										<h2>{translations.education}</h2>
										<ul>
											{member.education.map(
												(education, i) => (
													<li key={`education-${i}`}>
														{education}
													</li>
												)
											)}
										</ul>
									</div>
								)}
								{member?.seminars && (
									<div className='avlegalfirm-popupBox--cv_professionalData-list--wrapper'>
										<h2>{translations.seminars}</h2>
										<ul>
											{member.seminars.map(
												(seminar, i) => (
													<li key={`seminar-${i}`}>
														{seminar}
													</li>
												)
											)}
										</ul>
									</div>
								)}
								{member?.experience && (
									<div className='avlegalfirm-popupBox--cv_professionalData-list--wrapper'>
										<h2>{translations.experience}</h2>
										<ul>
											{member.experience.map(
												(experience, i) => (
													<li key={`experience-${i}`}>
														{experience}
													</li>
												)
											)}
										</ul>
									</div>
								)}
								{member?.publications && (
									<div className='avlegalfirm-popupBox--cv_professionalData-list--wrapper'>
										<h2>{translations.publications}</h2>
										<ul>
											{member.publications.map(
												(publication, i) => (
													<li
														key={`publication-${i}`}>
														{publication}
													</li>
												)
											)}
										</ul>
									</div>
								)}
								{member?.distinctions && (
									<div className='avlegalfirm-popupBox--cv_professionalData-list--wrapper'>
										<h2>{translations.distinctions}</h2>
										<ul>
											{member.distinctions.map(
												(distinction, i) => (
													<li
														key={`distinction-${i}`}>
														{distinction}
													</li>
												)
											)}
										</ul>
									</div>
								)}
								{member?.affiliations && (
									<div className='avlegalfirm-popupBox--cv_professionalData-list--wrapper'>
										<h2>{translations.affiliations}</h2>
										<ul>
											{member.affiliations.map(
												(affiliation, i) => (
													<li
														key={`affiliation-${i}`}>
														{affiliation}
													</li>
												)
											)}
										</ul>
									</div>
								)}
								{member?.cv && (
									<div className='avlegalfirm-popupBox--cv_professionalData-list--wrapper'>
										<ul>
											{member.cv.map((data, i) => (
												<li key={`cv-${i}`}>{data}</li>
											))}
										</ul>
									</div>
								)}
							</div>
						</div>
					</SwiperSlide>
				))}
			</Swiper>
		</section>
	);
};

MemberCV.defaultProps = {
	memberPosition: 0,
};

export default MemberCV;
