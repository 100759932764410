import React, { useState, useContext, useEffect } from "react";
import { AVContext } from "../../utils/AVContext";
import { useIsMobile } from "../../utils/UtilityService.jsx";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";
import { useNavigate } from "react-router-dom";
import { useIntersectionObserver } from "../../utils/UtilityService";
import data from "../../utils/data.json";
import en from "../../utils/en.json";
import es from "../../utils/es.json";
import MemberCard from "../MemberCard/MemberCard";
import "swiper/css";
import "swiper/css/pagination";
import "./Team.scss";

const Team = () => {
	const {
		state: { language },
		dispatch,
	} = useContext(AVContext);

	const { ref, isVisible, animated } = useIntersectionObserver();

	const isMobile = useIsMobile();

	const navigate = useNavigate();

	const translations = language === "en" ? en : es;

	const members = language === "en" ? data?.membersEN : data?.membersES;

	const [isOpen, setIsOpen] = useState(false);
	const [active, setActive] = useState(1);

	const togglePopup = (position = 0) => {
		setIsOpen(!isOpen);
		dispatch({
			type: "SET_MEMBER",
			payload: position,
		});
		window.scrollTo(0, 0);
		navigate("/team");
	};

	return (
		<section id='team' className='avlegalfirm-section' ref={ref}>
			<div className='avlegalfirm-team'>
				<h1 className='avlegalfirm-section--title1'>
					<span>{translations.legalTeam}</span>
				</h1>
				<h2 className='avlegalfirm-section--title2'>
					{translations.meetOurProfessionalTeam}
				</h2>
				<p className='avlegalfirm-team--description'>
					{translations.teamDescription}
				</p>
				{isMobile ? (
					<Swiper
						pagination={{
							dynamicBullets: true,
						}}
						modules={[Pagination]}>
						{members?.map((member, i) => (
							<SwiperSlide key={`${i}-${member.name}`}>
								<div
									id={`${i}-${member.name}`}
									className={`avlegalfirm-team--container_member ${
										active === i
											? "avlegalfirm-active--card"
											: ""
									}`}
									onClick={() => {
										togglePopup(
											i,
											`${member?.lastName?.toLowerCase()}-${member?.name?.toLowerCase()}`
										);
									}}>
									<MemberCard member={member} />
								</div>
							</SwiperSlide>
						))}
					</Swiper>
				) : (
					<div className='avlegalfirm-team--container'>
						{members?.map((member, i) => (
							<div
								id={`${i}-${member.name}`}
								key={`${i}-${member.name}`}
								className={`avlegalfirm-team--container_member ${
									active === i
										? "avlegalfirm-active--card"
										: ""
								}`}
								onMouseEnter={() => setActive(i)}
								onMouseLeave={() => setActive(-1)}>
								<MemberCard
									member={member}
									onClickBtn={() => {
										togglePopup(
											i,
											`${member?.lastName?.toLowerCase()}-${member?.name?.toLowerCase()}`
										);
									}}
								/>
							</div>
						))}
					</div>
				)}
			</div>
		</section>
	);
};

export default Team;
