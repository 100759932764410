import React, { createContext, useReducer } from "react";

const AVContext = createContext();

const initialState = { language: "en", activeMember: 0 };

const reducer = (state, action) => {
	switch (action.type) {
		case "SET_MEMBER":
			return { ...state, activeMember: action.payload };
		case "SET_LANGUAGE":
			return { ...state, language: action.payload };
		default:
			throw new Error();
	}
};

function AVProvider(props) {
	const [state, dispatch] = useReducer(reducer, initialState);

	return (
		<AVContext.Provider value={{ state, dispatch }}>
			{props.children}
		</AVContext.Provider>
	);
}

export { AVContext, AVProvider };
