import React, { useContext, useState } from "react";
import { AVContext } from "../../utils/AVContext";
import { BsBriefcaseFill } from "react-icons/bs";
import { HiHome } from "react-icons/hi";
import { FaFileInvoiceDollar } from "react-icons/fa";
import { GrUserWorker } from "react-icons/gr";
import { MdCorporateFare } from "react-icons/md";
import { BsPeopleFill } from "react-icons/bs";
import { ImHammer2 } from "react-icons/im";
import { useIntersectionObserver } from "../../utils/UtilityService";
import en from "../../utils/en.json";
import es from "../../utils/es.json";

import "./PracticeAreas.scss";

const PracticeAreas = () => {
	const {
		state: { language },
	} = useContext(AVContext);

	const { ref, isVisible, animated } = useIntersectionObserver();

	const translations = language === "en" ? en : es;

	const [active, setActive] = useState(1);

	return (
		<section id='practiceAreas' className='avlegalfirm-section' ref={ref}>
			<h1 className='avlegalfirm-section--title1'>
				<span>{translations.practiceAreas}</span>
			</h1>
			<h2 className='avlegalfirm-section--title2'>
				{translations.ourPracticeAreas}
			</h2>
			<div className='avlegalfirm-practiceAreas--cardsContainer'>
				{translations.practiceAreasList.map((area, i) => (
					<div
						key={`p-${i}`}
						className={`avlegalfirm-practiceAreas--cardsContainer_card ${
							active === i ? "avlegalfirm-active--card" : ""
						}`}
						onMouseEnter={() => setActive(i)}
						onMouseLeave={() => setActive(-1)}>
						<div className='avlegalfirm-practiceAreas--cardsContainer_card-icon'>
							{
								{
									BsBriefcaseFill: (
										<BsBriefcaseFill size='40px' />
									),
									HiHome: <HiHome size='40px' />,
									FaFileInvoiceDollar: (
										<FaFileInvoiceDollar size='40px' />
									),
									GrUserWorker: <GrUserWorker size='40px' />,
									MdCorporateFare: (
										<MdCorporateFare size='40px' />
									),
									BsPeopleFill: <BsPeopleFill size='40px' />,
									ImHammer2: <ImHammer2 size='40px' />,
								}[area.icon]
							}
						</div>
						<h3>{area.name}</h3>
						<p>{area.description}</p>
					</div>
				))}
			</div>
		</section>
	);
};

export default PracticeAreas;
