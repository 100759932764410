import React from "react";
import ReactDOM from "react-dom/client";
import { AVProvider } from "./utils/AVContext";
import Root from "./components/Root";
import "./index.css";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
	<React.StrictMode>
		<AVProvider>
			<Root />
		</AVProvider>
	</React.StrictMode>
);
