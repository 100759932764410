import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Navbar from "./NavBar/NavBar.jsx";
import Footer from "./Footer/Footer";
import Page from "./Page/Page.jsx";
import MemberCV from "./MemberCV/MemberCV";

const Root = () => {
	return (
		<div className='App'>
			<BrowserRouter>
				<Navbar />
				<Routes>
					<Route path='/' element={<Page />} />
					<Route path='/team' element={<MemberCV />} />
				</Routes>
				<Footer />
			</BrowserRouter>
		</div>
	);
};

export default Root;
