import React, { useContext } from "react";
import { ReactComponent as AVLogo } from "../../utils/images/logo.svg";
import { RxDotFilled } from "react-icons/rx";
import { TbCertificate } from "react-icons/tb";
import { BsFillTrophyFill } from "react-icons/bs";
import { GoLaw } from "react-icons/go";
import { AVContext } from "../../utils/AVContext";
import { scrollTo } from "../../utils/UtilityService.jsx";
import en from "../../utils/en.json";
import es from "../../utils/es.json";
import { gold } from "../../utils/AVConstants";
import "./Banner.scss";

const Banner = () => {
	const {
		state: { language },
	} = useContext(AVContext);

	const translations = language === "en" ? en : es;

	const bannerImg = require("../../utils/images/hole-team-removebg.png");

	return (
		<section id='banner'>
			<div className='avlegalfirm-banner avlegalfirm-section'>
				<div className='avlegalfirm-banner--contactus'>
					<AVLogo id='avLogo' color='#ffffff' />
					<div className='avlegalfirm-banner--contactus_etr'>
						<h1>{translations.experience}</h1>
						<RxDotFilled color={gold} />
						<h1>{translations.trust}</h1>
						<RxDotFilled color={gold} />
						<h1>{translations.results}</h1>
					</div>
					<button
						className='avlegalfirm-banner--contactus_btn'
						onClick={() => scrollTo("contactUs")}>
						{translations.contactUs}
					</button>
				</div>
				<div className='avlegalfirm-banner--team'>
					<img src={bannerImg} alt='AV Law Firm Team' />
				</div>
			</div>
			<div className='avlegalfirm-banner--cardsContainer'>
				<div className='avlegalfirm-banner--cardsContainer_card'>
					<TbCertificate size='40px' />
					<h3>{translations.expertAdvice}</h3>
					<p>{translations.lawyersHelpYou}</p>
				</div>
				<div className='avlegalfirm-banner--cardsContainer_card'>
					<BsFillTrophyFill size='40px' />
					<h3>{translations.skilledAttorneys}</h3>
					<p>{translations.skilledAttorneysDesc}</p>
				</div>
				<div className='avlegalfirm-banner--cardsContainer_card'>
					<GoLaw size='40px' />
					<h3>{translations.bestLegalDefense}</h3>
					<p>{translations.bestLegalDefenseDesc}</p>
				</div>
			</div>
		</section>
	);
};

export default Banner;
