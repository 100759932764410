import React, { useContext, useState } from "react";
import { AVContext } from "../../utils/AVContext";
import { RiArrowDownSFill } from "react-icons/ri";
import { RiArrowUpSFill } from "react-icons/ri";
import { BiCheck } from "react-icons/bi";
import "./Dropdown.scss";

const Dropdown = () => {
	const {
		state: { language },
		dispatch,
	} = useContext(AVContext);

	const [isOpen, setIsOpen] = useState(false);
	const [selectedOption, setSelectedOption] = useState(language);

	const toggleDropdown = () => {
		setIsOpen(!isOpen);
	};

	const handleOptionClick = (value) => {
		setSelectedOption(value);
		setIsOpen(false);
		dispatch({
			type: "SET_LANGUAGE",
			payload: value,
		});
	};

	return (
		<div className='dropdown'>
			<button onClick={toggleDropdown} className='dropdown-btn'>
				<span>
					<label>
						{selectedOption
							? selectedOption.charAt(0).toUpperCase() +
							  selectedOption.slice(1)
							: "En"}
					</label>
					{isOpen ? (
						<RiArrowUpSFill size='20px' />
					) : (
						<RiArrowDownSFill size='20px' />
					)}
				</span>
			</button>
			{isOpen && (
				<ul className='dropdown-menu'>
					<li onClick={() => handleOptionClick("en")}>
						<span>En {selectedOption === "en" && <BiCheck />}</span>
					</li>
					<li onClick={() => handleOptionClick("es")}>
						<span>Es {selectedOption === "es" && <BiCheck />}</span>
					</li>
				</ul>
			)}
		</div>
	);
};

export default Dropdown;
