import React, { useContext } from "react";
import { ReactComponent as AVLogo } from "../../utils/images/logo.svg";
import { AVContext } from "../../utils/AVContext";
import { MdOutlineTimeline } from "react-icons/md";
import { ImSun } from "react-icons/im";
import { FaHandsHelping } from "react-icons/fa";
import { gold } from "../../utils/AVConstants";
import { useIntersectionObserver } from "../../utils/UtilityService";
import en from "../../utils/en.json";
import es from "../../utils/es.json";
import "./AboutUs.scss";

const AboutUs = () => {
	const {
		state: { language },
	} = useContext(AVContext);

	const intersectionObserverOptions = {
		root: null,
		rootMargin: "0px",
		threshold: 0.5,
	};
	const { ref, isVisible, animated } = useIntersectionObserver(
		intersectionObserverOptions
	);

	const translations = language === "en" ? en : es;

	const aboutUsPicURL = require("../../utils/images/lawyers-nobg.png");

	return (
		<section id='aboutUs' className='avlegalfirm-section' ref={ref}>
			<div className='avlegalfirm-aboutUs'>
				<div className='avlegalfirm-aboutUs--container'>
					<div className='avlegalfirm-aboutUs--container_text'>
						<h1 className='avlegalfirm-aboutUs--container_text-aboutUs'>
							{translations.aboutUs}
						</h1>

						{translations.aboutUsText.map((element, i) => (
							<div
								id={`p-${i}`}
								key={`p-us-${i}`}
								className={`avlegalfirm-aboutUs--container_text-row ${
									isVisible && !animated
										? `rightToLeftAnimation-${i + 1}`
										: ""
								}`}>
								<div className='avlegalfirm-aboutUs--container_text-row--icon'>
									{
										{
											MdOutlineTimeline: (
												<MdOutlineTimeline size='30px' />
											),
											ImSun: <ImSun size='30px' />,
											FaHandsHelping: (
												<FaHandsHelping size='30px' />
											),
										}[element.icon]
									}
								</div>
								<div>
									<label>{element.title}</label>
									<p>{element.txt}</p>
								</div>
							</div>
						))}
					</div>
					<div className='avlegalfirm-aboutUs--container_img'>
						<AVLogo
							color={gold}
							className={`${
								isVisible && !animated
									? "bottomToTopAnimation-3"
									: ""
							}`}
						/>
						<img
							src={aboutUsPicURL}
							loading='lazy'
							alt='AV Law Firm'
							className={`${
								isVisible && !animated
									? "fadeInAnimation-2"
									: ""
							}`}
						/>
					</div>
				</div>
			</div>
		</section>
	);
};

export default AboutUs;
