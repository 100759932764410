import { useEffect, useState, useRef } from "react";

const scrollTo = (elementId) => {
	const element = document.getElementById(elementId).offsetTop;
	window.scrollTo({ top: element - 120, behavior: "smooth" });
};

const useIsMobile = () => {
	const [width, setWidth] = useState(window.innerWidth);
	const handleWindowSizeChange = () => {
		setWidth(window.innerWidth);
	};

	useEffect(() => {
		window.addEventListener("resize", handleWindowSizeChange);
		return () => {
			window.removeEventListener("resize", handleWindowSizeChange);
		};
	}, []);

	return width <= 768;
};

const useIntersectionObserver = (
	options = {
		root: null, // Use the viewport as the root
		rootMargin: "0px",
		threshold: 0.25, // Percentage of the target element visible to trigger callback
	}
) => {
	const targetRef = useRef(null);
	const [isVisible, setIsVisible] = useState(false);
	const [animated, setAnimated] = useState(false);

	useEffect(() => {
		const callback = (entries) => {
			entries.forEach((entry) => {
				if (entry.isIntersecting) {
					setIsVisible(true);
				} else {
					if (isVisible) {
						if (!animated) {
							setAnimated(true);
						}
						setIsVisible(false);
					}
				}
			});
		};

		const observer = new IntersectionObserver(callback, options);

		if (targetRef.current) {
			observer.observe(targetRef.current);
		}

		return () => {
			if (targetRef.current) {
				observer.unobserve(targetRef.current);
			}
		};
	}, [options]);

	return { ref: targetRef, isVisible, animated };
};

export { useIsMobile, scrollTo, useIntersectionObserver };
