import React, { useContext, useState } from "react";
import { AVContext } from "../../utils/AVContext";
import { IoLogoWhatsapp } from "react-icons/io";
import { MdEmail } from "react-icons/md";
import { FaPhoneSquareAlt } from "react-icons/fa";
import { BsFacebook } from "react-icons/bs";
import { MdLocationPin } from "react-icons/md";
import { useIsMobile } from "../../utils/UtilityService.jsx";
import { gold } from "../../utils/AVConstants";
import { useIntersectionObserver } from "../../utils/UtilityService";
import data from "../../utils/data.json";
import en from "../../utils/en.json";
import es from "../../utils/es.json";
//import axios from "axios";
import "./ContactUs.scss";

const ContactUs = () => {
	const {
		state: { language },
	} = useContext(AVContext);

	const { ref, isVisible, animated } = useIntersectionObserver();

	const initialFormState = {
		name: "",
		lastName: "",
		email: "",
		phone: "",
		message: "",
	};

	const [formData, setFormData] = useState(initialFormState);

	const translations = language === "en" ? en : es;

	const isMobile = useIsMobile();

	const handleSubmit = (event) => {
		event.preventDefault();
		console.log(formData);
		// axios({
		// 	method: "POST",
		// 	url: "http://localhost:5000/contact",
		// 	data: JSON.stringify(formData),
		// }).then((response) => {
		// 	if (response.data.status === "success") {
		// 		alert("Message Sent.");
		// 		resetForm();
		// 	} else if (response.data.status === "fail") {
		// 		alert("Message failed to send.");
		// 	}
		// });
	};

	const onFormChange = (event) => {
		const { name, value } = event.target;
		setFormData((prevFormState) => ({
			...prevFormState,
			[name]: value,
		}));
	};

	// const resetForm = () => {
	// 	setFormData(initialFormState);
	// };

	return (
		<section
			id='contactUs'
			ref={ref}
			className={`avlegalfirm-section ${
				isVisible && !animated ? "bottomToTopAnimation-1" : ""
			}`}>
			<div className='avlegalfirm-contactUs'>
				<h1 className='avlegalfirm-section--title1'>
					<span>{translations.contactUs}</span>
				</h1>
				<div className='avlegalfirm-contactUs--container'>
					<p className='mb-25'>
						{translations.contatcFormHelpTempText}
					</p>
					<div className='avlegalfirm-contactUs--container_links'>
						{isMobile ? (
							<>
								<div className='avlegalfirm-contactUs--container_links-item'>
									<a href={`mailto:${data.email}`}>
										<MdEmail color={gold} />
										{data.email}
									</a>
								</div>
								<div className='avlegalfirm-contactUs--container_links-item'>
									<a
										href={data.whatsAppURL}
										target='_blank'
										rel='noreferrer'>
										<IoLogoWhatsapp color={gold} />
										+506 8811 6137
									</a>
								</div>

								<div className='avlegalfirm-contactUs--container_links-item'>
									<a href='tel:+506-2743-8345'>
										<FaPhoneSquareAlt color={gold} />
										+506 2743-8345
									</a>
								</div>
								<div className='avlegalfirm-contactUs--container_links-item'>
									<a
										href={data.facebookURL}
										target='_blank'
										rel='noreferrer'>
										<BsFacebook color={gold} />
										{data.facebookName}
									</a>
								</div>
								<div className='avlegalfirm-contactUs--container_links-item'>
									<a href='geo:9.166095,-83.736956?q=9.166095,-83.736956'>
										<MdLocationPin color={gold} />
										Uvita, Osa, Puntarenas
									</a>
								</div>
								<div className='avlegalfirm-contactUs--container_links-item'>
									<a href='geo:9.374611,-83.702222?q=9.374611,-83.702222'>
										<MdLocationPin color={gold} />
										San Isidro, Pérez Zeledón, San José
									</a>
								</div>
							</>
						) : (
							<>
								<div className='avlegalfirm-contactUs--container_links-item'>
									<a href={`mailto:${data.email}`}>
										<MdEmail color={gold} />
										{data.email}
									</a>
								</div>
								<div className='avlegalfirm-contactUs--container_links-item'>
									<a
										href={data.whatsAppURL}
										target='_blank'
										rel='noreferrer'>
										<IoLogoWhatsapp color={gold} />
										+506 8811 6137
									</a>
								</div>
								<div className='avlegalfirm-contactUs--container_links-item'>
									<a href='tel:+506-2743-8345'>
										<FaPhoneSquareAlt color={gold} />
										+506 2743-8345
									</a>
								</div>
								{/* <div className='avlegalfirm-contactUs--container_links-item'>
									<a
										href={data.facebookURL}
										target='_blank'
										rel='noreferrer'>
										<BsFacebook color={gold} />
										{data.facebookName}
									</a>
								</div> */}
								<div className='avlegalfirm-contactUs--container_links-item'>
									<a
										href='https://www.google.com/maps/search/?api=1&query=9.166095,-83.736956'
										target='_blank'>
										<MdLocationPin color={gold} />
										Uvita, Osa, Puntarenas
									</a>
								</div>
								<div
									className='avlegalfirm-contactUs--container_links-item'
									target='_blank'>
									<a href='https://www.google.com/maps/search/?api=1&query=9.374611,-83.702222'>
										<MdLocationPin color={gold} />
										San Isidro, Pérez Zeledón, San José
									</a>
								</div>
							</>
						)}
					</div>
				</div>
				{/* <div className='avlegalfirm-contactUs--container'>
					<div className='avlegalfirm-contactUs--container_links'>
						<h2>{translations.contactForm}</h2>
						<p>{translations.contatcFormHelpText}</p>

						{!isMobile && (
							<>
								<div className='avlegalfirm-contactUs--container_links-item'>
									<a href={`mailto:${data.email}`}>
										<MdEmail />
										{data.email}
									</a>
								</div>
								<div className='avlegalfirm-contactUs--container_links-item'>
									<a
										href={data.whatsAppURL}
										target='_blank'
										rel='noreferrer'>
										<IoLogoWhatsapp />
										+506 8811 6137
									</a>
								</div>
								<div className='avlegalfirm-contactUs--container_links-item'>
									<a href='tel:+506-2743-8345'>
										<FaPhoneSquareAlt />
										+506 2743-8345
									</a>
								</div>
								<div className='avlegalfirm-contactUs--container_links-item'>
									<a
										href={data.facebookURL}
										target='_blank'
										rel='noreferrer'>
										<BsFacebook />
										{data.facebookName}
									</a>
								</div>
								<div className='avlegalfirm-contactUs--container_links-item'>
									<a
										href='https://www.google.com/maps/search/?api=1&query=9.166095,-83.736956'
										target='_blank'>
										<MdLocationPin />
										Uvita, Osa, Puntarenas
									</a>
								</div>
								<div
									className='avlegalfirm-contactUs--container_links-item'
									target='_blank'>
									<a href='https://www.google.com/maps/search/?api=1&query=9.374611,-83.702222'>
										<MdLocationPin />
										Pérez Zeledón, San José
									</a>
								</div>
							</>
						)}
					</div>
					<div className='avlegalfirm-contactUs--container_form'>
						<form onSubmit={handleSubmit}>
							<label htmlFor='name'>{translations.name}</label>
							<div className='avlegalfirm-contactUs--container_form-row'>
								<input
									id='name'
									name='name'
									type='text'
									placeholder={translations.namePlaceHolder}
									required={true}
									value={formData.name}
									onChange={(e) => onFormChange(e)}
								/>
								<input
									id='lastName'
									name='lastName'
									type='text'
									placeholder={
										translations.lastNamePlaceHolder
									}
									required={true}
									value={formData.lastName}
									onChange={(e) => onFormChange(e)}
								/>
							</div>
							<div className='avlegalfirm-contactUs--container_form-row'>
								<div>
									<label>{translations.phone}</label>
									<input
										id='phone'
										name='phone'
										type='phone'
										placeholder={
											translations.phonePlaceHolder
										}
										required={true}
										value={formData.phone}
										onChange={(e) => onFormChange(e)}
									/>
								</div>
								<div>
									<label>Email</label>
									<input
										id='email'
										name='email'
										type='email'
										placeholder='example@gmail.com'
										required={true}
										value={formData.email}
										onChange={(e) => onFormChange(e)}
									/>
								</div>
							</div>
							<label>{translations.howCanWehelpYou}</label>
							<textarea
								id='message'
								name='message'
								rows='8'
								cols='50'
								placeholder={
									translations.tellUsAboutYourSituation
								}
								required={true}
								value={formData.message}
								onChange={(e) => onFormChange(e)}
							/>
							<button type='submit'>
								{translations.sendMessage}
							</button>
						</form>
					</div>
					{isMobile && (
						<>
							<div className='avlegalfirm-contactUs--container_links-item'>
								<a href={`mailto:${data.email}`}>
									<MdEmail />
									{data.email}
								</a>
							</div>
							<div className='avlegalfirm-contactUs--container_links-item'>
								<a
									href={data.whatsAppURL}
									target='_blank'
									rel='noreferrer'>
									<IoLogoWhatsapp />
									+506 8811 6137
								</a>
							</div>

							<div className='avlegalfirm-contactUs--container_links-item'>
								<a href='tel:+506-2743-8345'>
									<FaPhoneSquareAlt />
									+506 2743-8345
								</a>
							</div>
							<div className='avlegalfirm-contactUs--container_links-item'>
								<a
									href={data.facebookURL}
									target='_blank'
									rel='noreferrer'>
									<BsFacebook />
									{data.facebookName}
								</a>
							</div>
							<div className='avlegalfirm-contactUs--container_links-item'>
								<a href='geo:9.166095,-83.736956?q=9.166095,-83.736956'>
									<MdLocationPin />
									Uvita, Osa, Puntarenas
								</a>
							</div>
							<div className='avlegalfirm-contactUs--container_links-item'>
								<a href='geo:9.374611,-83.702222?q=9.374611,-83.702222'>
									<MdLocationPin />
									Pérez Zeledón, San José
								</a>
							</div>
						</>
					)}
				</div> */}
			</div>
		</section>
	);
};

export default ContactUs;
