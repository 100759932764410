import React from "react";
import { ReactComponent as AVLogo } from "../../utils/images/logo.svg";
import { SiGmail } from "react-icons/si";
import { FaPhoneSquareAlt } from "react-icons/fa";
import { BsFacebook } from "react-icons/bs";
import data from "../../utils/data.json";
import "./Footer.scss";

const Footer = () => {
	return (
		<footer id='footer'>
			<div
				style={{
					height: "150px",
					overflow: "hidden",
					position: "absolute",
					top: "-125px",
					width: "100%",
					zIndex: "-1",
				}}>
				<svg
					viewBox='0 0 500 150'
					preserveAspectRatio='none'
					style={{ height: "100%", width: "100%" }}>
					<path
						d='M-0.27,107.08 C149.99,150.00 349.20,-49.98 500.00,49.98 L500.00,150.00 L0.00,150.00 Z'
						style={{ stroke: "none", fill: "#000066" }}></path>
				</svg>
			</div>
			<div className='avlegalfirm-footer'>
				<AVLogo height='100px' width='100px' />

				<div className='avlegalfirm-footer--contact'>
					<a href={data.facebookURL} target='_blank' rel='noreferrer'>
						<BsFacebook size='25px' />
					</a>
					<a href={`mailto:${data.email}`}>
						<SiGmail size='25px' />
					</a>
					<a href='tel:+506-2743-8345'>
						<FaPhoneSquareAlt size='25px' />
					</a>
				</div>
			</div>
			<p>&copy; Copyright AV Law Firm 2023. All Rights Reserved</p>
		</footer>
	);
};

export default Footer;
