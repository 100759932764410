import React, { useContext } from "react";
import { AVContext } from "../../utils/AVContext";
import { FaWhatsapp } from "react-icons/fa";
import { HiOutlineMail } from "react-icons/hi";
import { MdLocalPhone } from "react-icons/md";
import { HiOutlineArrowSmRight } from "react-icons/hi";
import { gold2 } from "../../utils/AVConstants";
import en from "../../utils/en.json";
import es from "../../utils/es.json";

const MemberCard = ({ member, onClickBtn }) => {
	const {
		state: { language },
	} = useContext(AVContext);

	const translations = language === "en" ? en : es;

	return (
		<>
			<div className='avlegalfirm-team--container_member-pic'>
				<img
					src={require(`../../utils/images/${member?.nobgPicture}`)}
					alt={`${member?.name} ${member?.lastName}`}
					loading='lazy'
				/>
			</div>
			<div className='avlegalfirm-team--container_member-info'>
				<h3>{`${member?.name} ${member?.lastName}`}</h3>
				{member?.practiceAreas?.map((area, i) => (
					<p key={`${i}-${area}`}>{area}</p>
				))}
			</div>
			<button
				className='avlegalfirm-team--container_member-seeMore'
				onClick={onClickBtn}>
				<span>
					{translations.seeMoreInfo}
					<HiOutlineArrowSmRight color={gold2} size='20px' />
				</span>
			</button>
			{/* <div className='avlegalfirm-team--container_member-contacts'>
				<a href={`https://wa.me/${member?.email}`}>
					<FaWhatsapp size='25px' color={gold2} />
				</a>
				<a href={`mailto:${member?.email}`}>
					<HiOutlineMail size='25px' color={gold2} />
				</a>
				<a href={`tel:+506-${member?.phoneNumber}`}>
					<MdLocalPhone size='25px' color={gold2} />
				</a>
			</div> */}
		</>
	);
};

export default MemberCard;
