import React from "react";
import Banner from "../Banner/Banner";
import PracticeAreas from "../PracticeAreas/PracticeAreas";
import Team from "../Team/Team";
import ContactUs from "../ContactUs/ContactUs";
import AboutUs from "../AboutUs/AboutUs";
import WelcomePage from "../WelcomePage/WelcomePage";
import "./Page.scss";

const Page = () => {
	return (
		<div className='avlegalfirm-container'>
			<Banner />
			<WelcomePage />
			<AboutUs />
			<Team />
			<PracticeAreas />
			<ContactUs />
		</div>
	);
};

export default Page;
