import React, { useState, useEffect, useContext } from "react";
import { scrollTo } from "../../utils/UtilityService.jsx";
import { ReactComponent as AVLogo } from "../../utils/images/simpleLogo.svg";
import { AVContext } from "../../utils/AVContext";
import { useNavigate } from "react-router-dom";
import { gold } from "../../utils/AVConstants.js";
import Dropdown from "../Dropdown/Dropdown.jsx";
import en from "../../utils/en.json";
import es from "../../utils/es.json";

import "./NavBar.scss";

const NavBar = () => {
	const {
		state: { language },
	} = useContext(AVContext);

	const [activeLink, setActiveLink] = useState();
	const [isSticky, setIsSticky] = useState(false);

	const translations = language === "en" ? en : es;

	const navigate = useNavigate();

	const sectionIds = [
		"banner",
		"aboutUs",
		"team",
		"practiceAreas",
		"contactUs",
	];
	const offset = 120;

	const scrollListener = () => {
		const scrollTop = window.scrollY;

		setIsSticky(
			scrollTop >= 110 || window.location.toString().includes("/team")
		);
		sectionIds.forEach((sectionId) => {
			const element = document.getElementById(sectionId);
			if (element && scrollTop >= element.offsetTop - offset) {
				setActiveLink(sectionId);
			}
		});
	};

	const handleLinkClick = (e, sectionId) => {
		e.preventDefault();
		if (window.location.toString().includes("/team")) {
			navigate("/");
		}
		scrollTo(sectionId);
		setActiveLink(sectionId);
	};

	useEffect(() => {
		window.addEventListener("scroll", scrollListener);
		if (window.location.toString().includes("/team")) {
			setIsSticky(true);
		}
		return () => {
			window.removeEventListener("scroll", scrollListener);
		};
	}, []);

	return (
		<nav className='avlegalfirm-navbar'>
			<div
				className={`avlegalfirm-navbar--header ${
					isSticky ? "sticky" : ""
				}`}>
				<div className='avlegalfirm-navbar--header_links'>
					{isSticky && (
						<AVLogo
							id='AVLogo'
							height='auto'
							width='60px'
							color={gold}
							onClick={(e) => handleLinkClick(e, "banner")}
						/>
					)}
					<p
						onClick={(e) => handleLinkClick(e, "aboutUs")}
						className={`${
							activeLink === "aboutUs"
								? "avlegalfirm-navbar--header_links-active"
								: ""
						}`}>
						{translations.aboutUs}
					</p>
					<p
						onClick={(e) => handleLinkClick(e, "team")}
						className={`${
							activeLink === "team"
								? "avlegalfirm-navbar--header_links-active"
								: ""
						}`}>
						{translations.legalTeam}
					</p>
					<p
						onClick={(e) => handleLinkClick(e, "practiceAreas")}
						className={`${
							activeLink === "practiceAreas"
								? "avlegalfirm-navbar--header_links-active"
								: ""
						}`}>
						{translations.practiceAreas}
					</p>
					<p
						onClick={(e) => handleLinkClick(e, "contactUs")}
						className={`${
							activeLink === "contactUs"
								? "avlegalfirm-navbar--header_links-active"
								: ""
						}`}>
						{translations.contact}
					</p>

					<Dropdown />
				</div>
			</div>
		</nav>
	);
};

export default NavBar;
